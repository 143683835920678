import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Outsourcers from "./Outsourcers";
import OutsourcerJob from "./OutsourcerJob";
import ClientDatabase from "./ClientDatabase";
import Staff from "./Staff/Staff";
import NewStaffForm from "./Staff/NewStaffForm";
import NewStaffFormOld from "./Staff/NewStaffFormOld";

import GarmentTracking from "./GarmentTracking";
import Div100vh from "react-div-100vh";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import RemoteSession from "./RemoteSession";
import EmailModal from "./Trello/EmailModal";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import "firebase/compat/auth";
import "firebase/compat/messaging";

import PhotoContest from "./PhotoContest";

import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { isDarkMode } from "./Utils/DetectLightDarkMode";

import { useIdleTimer } from "react-idle-timer";
import { useMediaQuery } from "@mui/material";

import { useAtom } from "jotai";
import {
  currentStaffDataAtom,
  userThemeAtom,
  themeAtom,
  userAtom,
  isIdleAtom,
  isElectronAtom,
  eventDataModalTabValueAtom,
  listsAtom,
  stylingListsAtom,
  labelsAtom,
  labelColoursAtom,
  showEveryJobAtom,
  staffDataAtom,
  isAdminAtom,
  isSuperAdminAtom,
  isMobileBrowserAtom,
  isMobileAtom,
  jobTypesAtom,
  isDevAtom,
  showWeekendsAtom,
  filterWhenCompletingAtom,
  groupWhenFilteringAtom,
  loginTokenAtom,
  // loginTokenCreatedAtom,
} from "./-Atoms";

import Jobs from "./Jobs";
import Layout from "./Layout";
import Login from "./Login";
import LoginOld from "./LoginOld";
import Admin from "./Admin";
import Calendar from "./Calendar/Calendar";
import Kanban from "./Kanban/Kanban";
import CustomGrid from "./CustomGrid/CustomGrid.js";
// import Styling from "./Styling/Styling";

import Dock from "./Dock";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// import { PhoneAuthProvider, linkWithCredential, unlink } from "firebase/auth";
import { PhoneAuthProvider, linkWithCredential } from "firebase/auth";

import DockFunctions from "./GlobalFunctions/DockFunctions";
import LoginElectron from "./LoginElectron";

// import { useElectronIdleTimer } from "./Login/electronIdleTimer.js";
import VideoSchedule from "./VideoSchedule.js";

import { signInWithCustomToken } from "firebase/auth";

const countryCodes = require("./countryCodes.json");

const firebaseConfig = {
  apiKey: "AIzaSyBvy9wwbHmVfNXy_XiaengbrcC0AeSHWp0",
  authDomain: "its-connect-main.firebaseapp.com",
  projectId: "its-connect-main",
  storageBucket: "its-connect-main.appspot.com",
  messagingSenderId: "1001127722110",
  appId: "1:1001127722110:web:7db56f4369dbc7a8a96c2f",
  measurementId: "G-KB3ZEJVTPK",
};

firebase.initializeApp(firebaseConfig);

// console.log(await firebase.messaging().getToken())
// firebase.messaging().onMessage(function (payload) {
//   console.log("Message received:", payload);
//   // Handle the received message here without showing a notification.
// });

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
getAnalytics(app);

// console.log(analytics);

const auth = firebase.auth();

function isElectronFunc() {
  // Renderer process
  if (
    typeof window !== "undefined" &&
    typeof window.process === "object" &&
    window.process.type === "renderer"
  ) {
    return true;
  }

  // Main process
  if (
    typeof process !== "undefined" &&
    typeof process.versions === "object" &&
    !!process.versions.electron
  ) {
    return true;
  }

  // Detect the user agent when the `nodeIntegration` option is set to true
  if (
    typeof navigator === "object" &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.indexOf("Electron") >= 0
  ) {
    return true;
  }

  return false;
}

function App() {
  const [isIdle] = useAtom(isIdleAtom);
  const [, setEventDataModalTabValue] = useAtom(eventDataModalTabValueAtom);

  const [, setLists] = useAtom(listsAtom);
  const [, setJobTypes] = useAtom(jobTypesAtom);
  const [, setStylingLists] = useAtom(stylingListsAtom);
  const [, setLabels] = useAtom(labelsAtom);
  const [, setLabelColours] = useAtom(labelColoursAtom);
  const [, setShowEveryJob] = useAtom(showEveryJobAtom);
  const [isAdmin] = useAtom(isAdminAtom);
  const [isSuperAdmin] = useAtom(isSuperAdminAtom);
  const [, setIsMobileAtom] = useAtom(isMobileAtom);
  const [isMobileBrowser, setIsMobileBrowserAtom] =
    useAtom(isMobileBrowserAtom);
  const isMobile = useMediaQuery("@media (max-width:780px)");
  const [isElectron, setIsElectron] = useAtom(isElectronAtom);
  const [currentStaffData, setCurrentStaffData] = useAtom(currentStaffDataAtom);
  const [isDev, setIsDev] = useAtom(isDevAtom);
  const [, setShowWeekends] = useAtom(showWeekendsAtom);
  // const [, setLoginToken] = useAtom(loginTokenAtom);
  // const [, setLoginTokenCreated] = useAtom(loginTokenCreatedAtom);

  const [loadingVisible, setLoadingVisible] = useState(true);

  // useEffect(() => {
  //   const main = async () => {
  //     const uid = localStorage.getItem("uid");
  //     const res = await firebase.functions().httpsCallable("getLoginTokens")({
  //       uid,
  //     });
  //     console.log(res.data.loginToken);
  //     setLoginToken(res.data.loginToken);
  //     setLoginTokenCreated(res.data.loginTokenCreated);
  //   };
  //   main();
  // }, []);

  // function getFirebaseLocalStorageValue() {
  //   return new Promise((resolve, reject) => {
  //     const request = indexedDB.open("firebaseLocalStorageDb");

  //     request.onerror = function (event) {
  //       reject("Error opening IndexedDB: " + event.target.errorCode);
  //     };

  //     request.onsuccess = function (event) {
  //       const db = event.target.result;
  //       const transaction = db.transaction(
  //         ["firebaseLocalStorage"],
  //         "readonly"
  //       );
  //       const objectStore = transaction.objectStore("firebaseLocalStorage");
  //       const getRequest = objectStore.get("firebaseLocalStorage");

  //       getRequest.onerror = function (event) {
  //         reject("Error getting document: " + event.target.errorCode);
  //       };

  //       getRequest.onsuccess = function (event) {
  //         if (getRequest.result) {
  //           resolve(getRequest.result.value);
  //         } else {
  //           resolve(null); // Document not found
  //         }
  //       };
  //     };
  //   });
  // }
  // eslint-disable-next-line
  // let loadingTimeout;
  useEffect(() => {
    setTimeout(() => {
      setLoadingVisible(false);
    }, 2500);
    // eslint-disable-next-line
  });

  useEffect(() => {
    setIsDev(process.env.NODE_ENV === "development" ? true : false);
  }, [setIsDev]);

  useEffect(() => {
    setIsMobileBrowserAtom(detectMobileBrowser());
  }, [setIsMobileBrowserAtom]);

  useEffect(() => {
    setEventDataModalTabValue(
      isMobileBrowser || isElectron
        ? isAdmin
          ? currentStaffData.id === "8jXVYoCymxHLQngklbWE"
            ? 2
            : 0
          : 2
        : 0
    );
  }, [
    isElectron,
    isMobile,
    isMobileBrowser,
    isAdmin,
    currentStaffData,
    setEventDataModalTabValue,
  ]);

  // const [showingCalendar] = useAtom(showingCalendarAtom);

  useEffect(() => {
    let unsub = firebase
      .firestore()
      .collection("AdminCollection")
      .doc("admin")
      .onSnapshot((doc) => {
        try {
          setJobTypes(doc.data().jobTypes);
        } catch (e) {
          setJobTypes([]);
          console.log(e);
        }
      });
    // if (isIdle) {
    //   unsub();
    // }
    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [firebase]);

  useEffect(() => {
    let unsub = firebase
      .firestore()
      .collection("AdminCollection")
      .doc("lists")
      .onSnapshot((doc) => {
        try {
          setLists(doc.data().lists);
        } catch (e) {
          setLists([]);
          console.log(e);
        }
      });
    // if (isIdle) {
    //   unsub();
    // }
    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [firebase]);

  useEffect(() => {
    let unsub = firebase
      .firestore()
      .collection("AdminCollection")
      .doc("stylingLists")
      .onSnapshot((doc) => {
        try {
          setStylingLists(doc.data().stylingLists);
        } catch (e) {
          console.log(e);
        }
      });
    // if (isIdle) {
    //   unsub();
    // }
    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [firebase]);

  useEffect(() => {
    let unsub = firebase
      .firestore()
      .collection("AdminCollection")
      .doc("labels")
      .onSnapshot((doc) => {
        try {
          setLabels(doc.data().labels);
          setLabelColours(doc.data().colours);
        } catch (e) {
          console.log(e);
        }
      });
    // if (isIdle) {
    //   unsub();
    // }
    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [firebase]);

  useEffect(() => {
    setIsMobileAtom(isMobile);
  }, [isMobile, setIsMobileAtom]);

  // eslint-disable-next-line
  useEffect(() => {
    setIsElectron(isElectronFunc());
    // eslint-disable-next-line
  }, []);

  // useElectronIdleTimer(auth);

  const onIdle = () => {
    // setIsIdle(true);
  };
  const onActive = () => {
    // setIsIdle(false);
  };
  const onAction = () => {};
  // 1800000
  useIdleTimer({
    onIdle,
    onActive,
    onAction,
    // timeout: 10000,
    // timeout: 2000,
    timeout: 7200000,
    throttle: 0,
  });

  useEffect(() => {
    let unsub = firebase
      .firestore()
      .collection("-Force Reload")
      .doc("ForceReload")
      .onSnapshot((doc) => {
        try {
          const data = doc.data();
          // data.id = doc.id;
          if (data.shouldReload) {
            window.location.reload();
          }
        } catch (e) {
          console.log(e);
        }
      });
    // if (isIdle) {
    //   unsub();
    // }
    return () => {
      unsub();
    };
    // NO IDLE ON THIS ONE
    // eslint-disable-next-line
  }, [firebase]);
  // NO IDLE ON THIS ONE

  useEffect(() => {
    let unsub = firebase
      .firestore()
      .collection("-Force Reload")
      .doc("ForceReloadNonAdmins")
      .onSnapshot((doc) => {
        try {
          const data = doc.data();
          // data.id = doc.id;
          if (data.shouldReload) {
            if (!isSuperAdmin) {
              if (!isAdmin) {
                window.location.reload();
              }
            }
          }
        } catch (e) {
          console.log(e);
        }
      });
    // if (isIdle) {
    //   unsub();
    // }
    return () => {
      unsub();
    };
    // NO IDLE ON THIS ONE
    // eslint-disable-next-line
  }, [firebase]);
  // NO IDLE ON THIS ONE

  const [user, setUser] = useAtom(userAtom);
  const [, setStaffData] = useAtom(staffDataAtom);
  const [, setIsAdmin] = useAtom(isAdminAtom);
  const [, setIsSuperAdmin] = useAtom(isSuperAdminAtom);

  const isDarkModeValue = isDarkMode();
  const [userTheme, setUserTheme] = useAtom(userThemeAtom);
  const [theme, setTheme] = useAtom(themeAtom);

  const [, setGroupWhenFiltering] = useAtom(groupWhenFilteringAtom);
  const [, setFilterWhenCompleting] = useAtom(filterWhenCompletingAtom);

  const [addPhoneLoginDialog, setAddPhoneLoginDialog] = useState(false);
  const handlePhoneLoginClose = () => {
    setAddPhoneLoginDialog(false);
  };

  const { hideDock } = DockFunctions();
  useEffect(() => {
    if (addPhoneLoginDialog) {
      hideDock();
    }
  }, [hideDock, addPhoneLoginDialog]);

  useEffect(() => {
    const main = () => {
      if (!currentStaffData) return;
      if (!user) return;
      setLoadingVisible(false);
      user.staffData = currentStaffData;

      const hasPhoneProvider = user.providerData.some(
        (obj) => obj.providerId === "phone"
      );
      setAddPhoneLoginDialog(!hasPhoneProvider);

      currentStaffData.hasOwnProperty("filterWhenCompleting")
        ? setFilterWhenCompleting(currentStaffData.filterWhenCompleting)
        : setFilterWhenCompleting(true);

      currentStaffData.hasOwnProperty("groupWhenFiltering")
        ? setGroupWhenFiltering(currentStaffData.groupWhenFiltering)
        : setGroupWhenFiltering(false);
    };
    main();
  }, [currentStaffData, user, setFilterWhenCompleting, setGroupWhenFiltering]);

  useEffect(() => {
    let unsub = firebase
      .firestore()
      .collection("StaffData")
      .orderBy("firstName", "asc")
      .onSnapshot((collection) => {
        const sd = collection.docs.map((staff) => {
          const staffDataRaw = staff.data();
          const data = { ...staffDataRaw };
          data.id = staff.id;
          // data.email = staffDataRaw.email;
          // data.firstName = staffDataRaw.firstName;
          // data.lastName = staffDataRaw.lastName;
          // data.isUtility = staffDataRaw.isUtility;
          // data.isAdmin = staffDataRaw.isAdmin;
          // data.isSuperAdmin = staffDataRaw.isSuperAdmin;
          // data.preferredName = staffDataRaw.preferredName;
          // data.avatar = staffDataRaw.avatar;
          // data.userTheme = staffDataRaw.userTheme;
          if (staffDataRaw.currentlyEmployed) {
            return data;
          } else {
            return false;
          }
        });
        let newSd = [];
        sd.forEach((s) => {
          if (s) newSd.push(s);
          // if (s.email === user?.email) {
          if (user?.uid && s?.uid) {
            if (s.uid === user.uid) {
              localStorage.setItem("uid", s.uid);
              setCurrentStaffData(s);
              if (s.isAdmin || s.isSuperAdmin) {
                setIsAdmin(true);
              }
              if (s.isSuperAdmin) {
                setIsSuperAdmin(true);
              }
            }
          }
        });
        setStaffData([...newSd]);
      });
    // if (isIdle) {
    //   unsub();
    // }

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [firebase, user, isIdle]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      setLoadingVisible(false);
      // clearTimeout(loadingTimeout);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // console.log(user?.staffData?.showEveryJob)
    if (user?.staffData?.showEveryJob) {
      setShowEveryJob(user?.staffData?.showEveryJob);
    } else {
      setShowEveryJob(true);
    }
  }, [user, setShowEveryJob]);

  useEffect(() => {
    setTheme(isDarkModeValue ? "dark" : "light");
    // eslint-disable-next-line
  }, [isDarkModeValue]);

  const handleThemeChange = async (event, newTheme) => {
    if (!newTheme) return;
    setUserTheme(newTheme);
    setTheme(
      newTheme === "automatic" ? (isDarkModeValue ? "dark" : "light") : newTheme
    );
    firebase
      .firestore()
      .collection("StaffData")
      .doc(user.staffData.id)
      .update({ userTheme: newTheme || null });
  };

  useEffect(() => {
    if (currentStaffData.userTheme) {
      setUserTheme(currentStaffData.userTheme);

      setTheme(
        currentStaffData.userTheme === "automatic"
          ? isDarkModeValue
            ? "dark"
            : "light"
          : currentStaffData.userTheme
      );
    } else {
      setUserTheme("automatic");
      setTheme(isDarkModeValue ? "dark" : "light");
    }
    setShowWeekends(currentStaffData.showWeekends);
    // eslint-disable-next-line
  }, [isDarkModeValue, currentStaffData]);

  const muiTheme = createTheme({
    palette: {
      mode: theme,
      background: {
        default: theme === "dark" ? "#1f1f1f" : "#ffffff",
      },
    },
  });

  useEffect(() => {
    const handleGlobalLogout = () => {
      if (user) {
        auth.signOut();
        window?.ipcRenderer?.send("sendSwiftLogout");
      }
    };
    const handleGlobalLogin = (token) => {
      signInWithCustomToken(auth, token)
        .then((userCredential) => {})
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode);
          console.log(errorMessage);
        });
    };
    window.handleGlobalLogout = handleGlobalLogout;
    window.handleGlobalLogin = handleGlobalLogin;

    if (isElectron && window.ipcRenderer) {
      window.ipcRenderer?.on("electronLogout", handleGlobalLogout);
      return () => {
        window.ipcRenderer.removeListener("electronLogout", handleGlobalLogout);
      };
    }
  }, [isElectron, user]);

  useEffect(() => {
    let unsub = firebase
      .firestore()
      .collection("-Force Reload")
      .doc("mrGrinchPlay")
      .onSnapshot((doc) => {
        try {
          const data = doc.data();
          // HJuoDbGzizKBYWqSWbrp
          // 8jXVYoCymxHLQngklbWE
          if (
            data.mrGrinchPlay &&
            currentStaffData.id === "HJuoDbGzizKBYWqSWbrp"
          ) {
            if (isElectron) {
              window.ipcRenderer?.send(
                "eval",
                `applescript('tell application "Finder" to set volume 4')`
              );
              setTimeout(() => {
                document.getElementById("mrGrinchAudio").play();
              }, 100);
              setTimeout(() => {
                window.ipcRenderer?.send(
                  "eval",
                  `applescript('tell application "Finder" to set volume 0')`
                );
              }, 5000);
            }
            // console.log("shouldPlay");
          }
        } catch (e) {
          console.log(e);
        }
      });
    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [firebase, currentStaffData]);

  // setTimeout(() => {
  //   document.getElementById("mrGrinchAudio").play();
  //   console.log
  // }, 2000);

  return (
    <>
      {/* {!currentStaffData && !user ? ( */}
      {/* <LoadingFullScreen
        loadingVisible={loadingVisible}
        theme={theme}
        user={user}
      /> */}
      {/* ) : null} */}
      <Div100vh>
        {/* <button
          onClick={async () => {
            await firebase
              .firestore()
              .collection("-Force Reload")
              .doc("mrGrinchPlay")
              .update({ mrGrinchPlay: true });
            await firebase
              .firestore()
              .collection("-Force Reload")
              .doc("mrGrinchPlay")
              .update({ mrGrinchPlay: false });
          }}
        >
          mrGrinchPlay
        </button> */}
        <audio id="mrGrinchAudio">
          <source
            autoPlay
            src="/Youre_A_Mean_One_Mr_Grinch.mp3"
            type="audio/mpeg"
          />
        </audio>
        <div id="main-app-container"></div>
        <MuiThemeProvider theme={muiTheme}>
          <CssBaseline />
          <AddPhoneLoginDialog
            open={addPhoneLoginDialog}
            handleClose={handlePhoneLoginClose}
            user={currentStaffData}
          />
          <Router>
            <Switch>
              <Route
                path="/loginOld"
                exact
                render={() => <LoginOld auth={auth} firebase={firebase} />}
              />
              <Route
                path="/"
                exact
                render={() =>
                  user ? (
                    <LayoutComponent
                      user={user}
                      auth={auth}
                      firebase={firebase}
                      userTheme={userTheme}
                      handleThemeChange={handleThemeChange}
                      theme={theme}
                      Component={
                        <>
                          <Dock
                            restricted={user ? false : true}
                            firebase={firebase}
                          />
                          <Calendar
                            firebase={firebase}
                            user={user}
                            userTheme={userTheme}
                            handleThemeChange={handleThemeChange}
                            theme={theme}
                          />
                        </>
                      }
                    />
                  ) : !isElectron ? (
                    <LoadingComponent
                      Component={<Login auth={auth} firebase={firebase} />}
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  ) : (
                    <LoadingComponent
                      Component={
                        <LoginElectron auth={auth} firebase={firebase} />
                      }
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  )
                }
              />
              <Route
                path="/calendar"
                exact
                render={() =>
                  user ? (
                    <>
                      <Dock
                        restricted={user ? false : true}
                        firebase={firebase}
                      />
                      <LayoutComponent
                        user={user}
                        auth={auth}
                        userTheme={userTheme}
                        handleThemeChange={handleThemeChange}
                        theme={theme}
                        firebase={firebase}
                        Component={
                          <Calendar
                            firebase={firebase}
                            user={user}
                            userTheme={userTheme}
                            handleThemeChange={handleThemeChange}
                            theme={theme}
                          />
                        }
                      />
                    </>
                  ) : (
                    <LoadingComponent
                      Component={<Login auth={auth} firebase={firebase} />}
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  )
                }
              />
              <Route
                path="/calendar/autologin"
                exact
                render={() =>
                  user ? (
                    <>
                      <Dock
                        restricted={user ? false : true}
                        firebase={firebase}
                      />
                      <LayoutComponent
                        user={user}
                        auth={auth}
                        userTheme={userTheme}
                        handleThemeChange={handleThemeChange}
                        theme={theme}
                        firebase={firebase}
                        Component={
                          <Calendar
                            firebase={firebase}
                            user={user}
                            userTheme={userTheme}
                            handleThemeChange={handleThemeChange}
                            theme={theme}
                          />
                        }
                      />
                    </>
                  ) : !isElectron ? (
                    <LoadingComponent
                      Component={<Login auth={auth} firebase={firebase} />}
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  ) : (
                    <LoadingComponent
                      Component={
                        <LoginElectron auth={auth} firebase={firebase} />
                      }
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  )
                }
              />
              <Route
                path="/kanban"
                exact
                render={() =>
                  user ? (
                    <>
                      <Dock
                        restricted={user ? false : true}
                        firebase={firebase}
                      />
                      <LayoutComponent
                        user={user}
                        auth={auth}
                        userTheme={userTheme}
                        handleThemeChange={handleThemeChange}
                        theme={theme}
                        firebase={firebase}
                        Component={<Kanban firebase={firebase} user={user} />}
                      />
                    </>
                  ) : !isElectron ? (
                    <LoadingComponent
                      Component={<Login auth={auth} firebase={firebase} />}
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  ) : (
                    <LoadingComponent
                      Component={
                        <LoginElectron auth={auth} firebase={firebase} />
                      }
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  )
                }
              />
              <Route
                path="/kanban/autologin"
                exact
                render={() =>
                  user ? (
                    <>
                      <Dock
                        restricted={user ? false : true}
                        firebase={firebase}
                      />
                      <LayoutComponent
                        user={user}
                        auth={auth}
                        userTheme={userTheme}
                        handleThemeChange={handleThemeChange}
                        theme={theme}
                        firebase={firebase}
                        Component={<Kanban firebase={firebase} user={user} />}
                      />
                    </>
                  ) : !isElectron ? (
                    <LoadingComponent
                      Component={<Login auth={auth} firebase={firebase} />}
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  ) : (
                    <LoadingComponent
                      Component={
                        <LoginElectron auth={auth} firebase={firebase} />
                      }
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  )
                }
              />
              <Route
                path="/videoschedule"
                exact
                render={() =>
                  user ? (
                    <>
                      <Dock
                        restricted={user ? false : true}
                        firebase={firebase}
                      />
                      <LayoutComponent
                        user={user}
                        auth={auth}
                        userTheme={userTheme}
                        handleThemeChange={handleThemeChange}
                        theme={theme}
                        firebase={firebase}
                        Component={
                          <VideoSchedule firebase={firebase} user={user} />
                        }
                      />
                    </>
                  ) : !isElectron ? (
                    <LoadingComponent
                      Component={<Login auth={auth} firebase={firebase} />}
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  ) : (
                    <LoadingComponent
                      Component={
                        <LoginElectron auth={auth} firebase={firebase} />
                      }
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  )
                }
              />
              <Route
                path="/shotlist"
                exact
                render={
                  () =>
                    user ? (
                      <CustomGrid firebase={firebase} user={user} />
                    ) : (
                      <LoginElectron auth={auth} firebase={firebase} />
                    )

                  // user ? (
                  //   <>
                  //     <Dock
                  //       restricted={user ? false : true}
                  //       firebase={firebase}
                  //     />
                  //     <LayoutComponent
                  //       user={user}
                  //       auth={auth}
                  //       userTheme={userTheme}
                  //       handleThemeChange={handleThemeChange}
                  //       theme={theme}
                  //       firebase={firebase}
                  //       Component={
                  //         <CustomGrid firebase={firebase} user={user} />
                  //       }
                  //     />
                  //   </>
                  // ) : !isElectron ? (
                  //   <LoadingComponent
                  //     Component={<Login auth={auth} firebase={firebase} />}
                  //     loadingVisible={loadingVisible}
                  //     theme={theme}
                  //     user={user}
                  //   />
                  // ) : (
                  //   <LoadingComponent
                  //     Component={
                  //       <LoginElectron auth={auth} firebase={firebase} />
                  //     }
                  //     loadingVisible={loadingVisible}
                  //     theme={theme}
                  //     user={user}
                  //   />
                  // )
                }
              />
              {/* <Route
              path="/kanban/autologin"
              exact
              render={() => (
                <>
                  <Dock
                            restricted={user ? false : true}
                            firebase={firebase}
                          />
                  <LayoutComponent
                    user={user}
                    auth={auth}
                    anon={true}
                    userTheme={userTheme}
                    handleThemeChange={handleThemeChange}
                    theme={theme}
                    firebase={firebase}
                    Component={<Kanban firebase={firebase} user={user} />}
                  />
                </>
              )}
            /> */}
              {/* <Route
              path="/calendar/autologin"
              exact
              render={() => (
                <>
                  <Dock
                            restricted={user ? false : true}
                            firebase={firebase}
                          />
                  <LayoutComponent
                    user={user}
                    auth={auth}
                    userTheme={userTheme}
                    handleThemeChange={handleThemeChange}
                    theme={theme}
                    firebase={firebase}
                    Component={
                      <Calendar
                        firebase={firebase}
                        user={user}
                        anon={true}
                        userTheme={userTheme}
                        handleThemeChange={handleThemeChange}
                        theme={theme}
                      />
                    }
                  />
                </>
              )}
            /> */}
              <Route
                path="/jobs"
                exact
                render={() => {
                  return user ? (
                    <LayoutComponent
                      user={user}
                      auth={auth}
                      firebase={firebase}
                      userTheme={userTheme}
                      handleThemeChange={handleThemeChange}
                      theme={theme}
                      Component={
                        currentStaffData?.isUtility ? (
                          <Calendar
                            firebase={firebase}
                            user={user}
                            userTheme={userTheme}
                            handleThemeChange={handleThemeChange}
                            theme={theme}
                          />
                        ) : (
                          <Jobs
                            user={user}
                            userTheme={userTheme}
                            handleThemeChange={handleThemeChange}
                            theme={theme}
                          />
                        )
                      }
                    />
                  ) : !isElectron ? (
                    <LoadingComponent
                      Component={<Login auth={auth} firebase={firebase} />}
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  ) : (
                    <LoadingComponent
                      Component={
                        <LoginElectron auth={auth} firebase={firebase} />
                      }
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  );
                }}
              />
              {/* <Route
              path="/styling/autologin"
              exact
              render={() => (
                <LayoutComponent
                  user={user}
                  auth={auth}
                  userTheme={userTheme}
                  handleThemeChange={handleThemeChange}
                  theme={theme}
                  firebase={firebase}
                  Component={
                    <Styling
                      firebase={firebase}
                      user={user}
                      userTheme={userTheme}
                      handleThemeChange={handleThemeChange}
                      theme={theme}
                    />
                  }
                />
              )}
            /> */}
              {/* <Route
              path="/styling"
              exact
              render={() => {
                return user ? (
                  <LayoutComponent
                    user={user}
                    auth={auth}
                    firebase={firebase}
                    userTheme={userTheme}
                    handleThemeChange={handleThemeChange}
                    theme={theme}
                    Component={
                      <Styling
                        firebase={firebase}
                        user={user}
                        userTheme={userTheme}
                        handleThemeChange={handleThemeChange}
                        theme={theme}
                      />
                    }
                  />
                ) : !isElectron ? (
                  <Login auth={auth} firebase={firebase} />
                ) : (
                  <LoginElectron auth={auth} firebase={firebase} />
                );
              }}
            /> */}
              <Route
                path="/clients"
                exact
                render={() =>
                  user ? (
                    <>
                      <Dock
                        restricted={user ? false : true}
                        firebase={firebase}
                      />
                      <LayoutComponent
                        user={user}
                        auth={auth}
                        firebase={firebase}
                        userTheme={userTheme}
                        handleThemeChange={handleThemeChange}
                        theme={theme}
                        Component={
                          currentStaffData?.isUtility ? (
                            <Calendar
                              firebase={firebase}
                              user={user}
                              userTheme={userTheme}
                              handleThemeChange={handleThemeChange}
                              theme={theme}
                            />
                          ) : (
                            <ClientDatabase
                              firebase={firebase}
                              userTheme={userTheme}
                              handleThemeChange={handleThemeChange}
                              theme={theme}
                            />
                          )
                        }
                      />
                    </>
                  ) : !isElectron ? (
                    <LoadingComponent
                      Component={<Login auth={auth} firebase={firebase} />}
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  ) : (
                    <LoadingComponent
                      Component={
                        <LoginElectron auth={auth} firebase={firebase} />
                      }
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  )
                }
              />
              <Route
                path="/staff"
                exact
                render={() =>
                  user ? (
                    <>
                      <Dock
                        restricted={user ? false : true}
                        firebase={firebase}
                      />
                      <LayoutComponent
                        user={user}
                        auth={auth}
                        firebase={firebase}
                        userTheme={userTheme}
                        handleThemeChange={handleThemeChange}
                        theme={theme}
                        Component={
                          currentStaffData?.isUtility ? (
                            <Calendar
                              firebase={firebase}
                              user={user}
                              userTheme={userTheme}
                              handleThemeChange={handleThemeChange}
                              theme={theme}
                            />
                          ) : (
                            <Staff
                              firebase={firebase}
                              user={user}
                              userTheme={userTheme}
                              handleThemeChange={handleThemeChange}
                              theme={theme}
                            />
                          )
                        }
                      />
                    </>
                  ) : !isElectron ? (
                    <LoadingComponent
                      Component={<Login auth={auth} firebase={firebase} />}
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  ) : (
                    <LoadingComponent
                      Component={
                        <LoginElectron auth={auth} firebase={firebase} />
                      }
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  )
                }
              />
              <Route
                path="/staff/newstaff/"
                exact
                render={() => <NewStaffForm firebase={firebase} />}
              />
              <Route
                path="/staff/newstaff/:uid"
                exact
                render={() =>
                  isDev ? (
                    <NewStaffForm firebase={firebase} />
                  ) : (
                    <NewStaffFormOld firebase={firebase} />
                  )
                }
              />

              <Route
                path="/outsourcerjob/:uid"
                exact
                render={() => <OutsourcerJob firebase={firebase} />}
              />
              <Route
                path="/outsourcers"
                exact
                render={() =>
                  user ? (
                    <LayoutComponent
                      user={user}
                      auth={auth}
                      firebase={firebase}
                      userTheme={userTheme}
                      handleThemeChange={handleThemeChange}
                      theme={theme}
                      Component={
                        currentStaffData?.isUtility ? (
                          <Calendar
                            firebase={firebase}
                            user={user}
                            userTheme={userTheme}
                            handleThemeChange={handleThemeChange}
                            theme={theme}
                          />
                        ) : (
                          <>
                            <Dock
                              restricted={user ? false : true}
                              firebase={firebase}
                            />
                            <Outsourcers
                              firebase={firebase}
                              user={user}
                              userTheme={userTheme}
                              handleThemeChange={handleThemeChange}
                              theme={theme}
                            />
                          </>
                        )
                      }
                    />
                  ) : !isElectron ? (
                    <LoadingComponent
                      Component={<Login auth={auth} firebase={firebase} />}
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  ) : (
                    <LoadingComponent
                      Component={
                        <LoginElectron auth={auth} firebase={firebase} />
                      }
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  )
                }
              />
              <Route
                path="/garmenttracking"
                exact
                render={() =>
                  user ? (
                    <LayoutComponent
                      user={user}
                      auth={auth}
                      userTheme={userTheme}
                      handleThemeChange={handleThemeChange}
                      theme={theme}
                      firebase={firebase}
                      Component={
                        currentStaffData?.isUtility ? (
                          <Calendar
                            firebase={firebase}
                            user={user}
                            userTheme={userTheme}
                            handleThemeChange={handleThemeChange}
                            theme={theme}
                          />
                        ) : (
                          <GarmentTracking
                            userTheme={userTheme}
                            handleThemeChange={handleThemeChange}
                            theme={theme}
                          />
                        )
                      }
                    />
                  ) : !isElectron ? (
                    <LoadingComponent
                      Component={<Login auth={auth} firebase={firebase} />}
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  ) : (
                    <LoadingComponent
                      Component={
                        <LoginElectron auth={auth} firebase={firebase} />
                      }
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  )
                }
              />
              <Route
                path="/admin"
                exact
                render={() =>
                  user ? (
                    <>
                      <Dock
                        restricted={user ? false : true}
                        firebase={firebase}
                      />
                      <LayoutComponent
                        user={user}
                        auth={auth}
                        firebase={firebase}
                        userTheme={userTheme}
                        handleThemeChange={handleThemeChange}
                        theme={theme}
                        Component={
                          currentStaffData?.isUtility ? (
                            <Calendar
                              firebase={firebase}
                              user={user}
                              userTheme={userTheme}
                              handleThemeChange={handleThemeChange}
                              theme={theme}
                            />
                          ) : (
                            <Admin
                              firebase={firebase}
                              userTheme={userTheme}
                              handleThemeChange={handleThemeChange}
                              theme={theme}
                            />
                          )
                        }
                      />
                    </>
                  ) : !isElectron ? (
                    <LoadingComponent
                      Component={<Login auth={auth} firebase={firebase} />}
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  ) : (
                    <LoadingComponent
                      Component={
                        <LoginElectron auth={auth} firebase={firebase} />
                      }
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  )
                }
              />
              <Route
                path="/photocontest"
                exact
                render={() =>
                  user ? (
                    <LayoutComponent
                      user={user}
                      auth={auth}
                      firebase={firebase}
                      userTheme={userTheme}
                      handleThemeChange={handleThemeChange}
                      theme={theme}
                      Component={
                        <PhotoContest
                          firebase={firebase}
                          user={user}
                          userTheme={userTheme}
                          handleThemeChange={handleThemeChange}
                          theme={theme}
                        />
                      }
                    />
                  ) : !isElectron ? (
                    <LoadingComponent
                      Component={<Login auth={auth} firebase={firebase} />}
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  ) : (
                    <LoadingComponent
                      Component={
                        <LoginElectron auth={auth} firebase={firebase} />
                      }
                      loadingVisible={loadingVisible}
                      theme={theme}
                      user={user}
                    />
                  )
                }
              />
              <Route
                path="/emailmodal"
                exact
                render={() => <EmailModal firebase={firebase} theme={theme} />}
              />
              <Route
                path="/session/:sessionid"
                render={() => (
                  <RemoteSession firebase={firebase} theme={theme} />
                )}
              />
              {/* <Route path="/emailgifx" component={ImageComponent} /> */}
              {/* Other routes here */}
            </Switch>
          </Router>
        </MuiThemeProvider>
      </Div100vh>
    </>
  );
}

export default App;

const LayoutComponent = ({
  user,
  auth,
  firebase,
  Component,
  search,
  setSearch,
  userTheme,
  handleThemeChange,
  theme,
}) => {
  return (
    <Layout
      user={user}
      auth={auth}
      firebase={firebase}
      search={search}
      setSearch={setSearch}
      userTheme={userTheme}
      handleThemeChange={handleThemeChange}
      theme={theme}
    >
      {Component}
    </Layout>
  );
};

const LoadingComponent = ({ Component, loadingVisible, theme, user }) => {
  return (
    <>
      <LoadingFullScreen
        loadingVisible={loadingVisible}
        theme={theme}
        user={user}
      />
      {Component}
    </>
  );
};

// eslint-disable-next-line
function detectMobileBrowser() {
  // eslint-disable-next-line
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  // eslint-disable-next-line
  if (
    // eslint-disable-next-line
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      ua
    ) ||
    // eslint-disable-next-line
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      ua.substr(0, 4)
    )
  ) {
    return true;
  } else {
    return false;
  }
}

const AddPhoneLoginDialog = ({ open, handleClose, user }) => {
  const { showDock } = DockFunctions();

  const [phone, setPhone] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("AU");
  const [verifyingNumber, setVerifyingNumber] = useState(false);
  const [verifyingButtonText, setVerifyingButtonText] = useState("Verify");
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeButtonText, setVerificationCodeButtonText] =
    useState("Next");
  const [numberVerified, setNumberVerified] = useState(false);

  const [applicationVerifier, setApplicationVerifier] = useState(null);
  const [verificationId, setVerificationId] = useState(null);

  // const buttonRef =

  useEffect(() => {
    const av = new firebase.auth.RecaptchaVerifier("main-app-container", {
      size: "invisible",
      callback: (response) => {},
    });
    setApplicationVerifier(av);
  }, [setApplicationVerifier]);

  const handleVerify = async () => {
    if (verifyingNumber) return;
    setVerifyingNumber(true);
    setVerifyingButtonText(
      <CircularProgress size={24} sx={{ color: "black" }} />
    );

    const cc = countryCodes.find((obj) => obj.code === selectedCountryCode);

    const provider = new PhoneAuthProvider(auth);
    const verificationId = await provider
      .verifyPhoneNumber(`${cc.dialCode}${phone}`, applicationVerifier)
      .catch((error) => {
        console.log(error);
        applicationVerifier.render().then(function (widgetId) {
          window.grecaptcha.reset(widgetId);
        });
        alert("Invalid Phone Number, please ensure the number is correct");
        setPhone("");
        setVerifyingNumber(false);
        setVerifyingButtonText("Verify");
      });
    if (verificationId) {
      setVerificationId(verificationId);
      setVerifyingNumber(false);
      setNumberVerified(true);
    }
  };

  return (
    <Dialog open={open} onClose={() => {}}>
      <DialogTitle>Hi {user.firstName}!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          We are transitioning from a{" "}
          <span style={{ fontWeight: "bold" }}>Password</span> based login to a{" "}
          <span style={{ fontWeight: "bold" }}>Phone Number</span> login.
        </DialogContentText>
        <DialogContentText sx={{ marginTop: 2 }}>
          We just need you to enter your phone number (and country code), You
          will then receive a verification code via SMS.
        </DialogContentText>
        {/* <Button
          onClick={() => {
            console.log(auth.currentUser);
            unlink(auth.currentUser, "phone")
              .then(() => {
                console.log("unlinked");
                console.log(auth.currentUser);
              })
              .catch((error) => {
                console.log(error);
              });
          }}
        >
          unlink
        </Button> */}

        {!numberVerified ? (
          <>
            <div
              style={{ display: "flex", gap: 15, width: "100%", marginTop: 15 }}
            >
              <FormControl
                disabled={verifyingNumber}
                variant="filled"
                sx={{ width: 300 }}
                size="small"
              >
                <InputLabel id="countryCodeSelectorLabel">
                  Country Code
                </InputLabel>
                <Select
                  labelId="countryCodeSelectorLabel"
                  value={selectedCountryCode}
                  label="Country Code"
                  onChange={(e) => {
                    setSelectedCountryCode(e.target.value);
                  }}
                  disableUnderline
                >
                  {countryCodes &&
                    countryCodes.map((cc, index) => (
                      <MenuItem
                        key={`${cc.code}${index}`}
                        value={cc.code}
                      >{`${cc.emoji} ${cc.name} (${cc.dialCode})`}</MenuItem>
                    ))}
                </Select>
              </FormControl>

              <TextField
                variant="filled"
                disabled={verifyingNumber}
                size="small"
                label="Phone Number"
                value={phone || ""}
                fullWidth
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/[^0-9]/g, "");
                  setPhone(numericValue);
                }}
                InputProps={{ disableUnderline: true }}
              />
            </div>

            <Button
              variant="contained"
              fullWidth
              sx={{ marginTop: 2 }}
              disabled={!phone}
              onClick={handleVerify}
            >
              {verifyingButtonText}
            </Button>
          </>
        ) : (
          <>
            <TextField
              variant="filled"
              sx={{ marginTop: 2 }}
              size="small"
              label="Verification Code"
              value={verificationCode || ""}
              fullWidth
              onChange={(e) => {
                setVerificationCode(e.target.value);
              }}
              InputProps={{ disableUnderline: true }}
            />
            <Button
              sx={{ marginTop: 2 }}
              variant="contained"
              fullWidth
              onClick={async () => {
                setVerificationCodeButtonText(
                  <CircularProgress size={24} sx={{ color: "black" }} />
                );

                const phoneCredential = PhoneAuthProvider.credential(
                  verificationId,
                  verificationCode
                );

                linkWithCredential(auth.currentUser, phoneCredential)
                  .then((usercred) => {
                    const user = usercred.user;
                    showDock();
                    document.getElementById("main-app-container").remove();
                    handleClose();
                    console.log("Account linking success", user);
                  })
                  .catch((error) => {
                    console.log("Account linking error", error);
                  });
              }}
            >
              {verificationCodeButtonText}
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

const LoadingFullScreen = ({ loadingVisible, theme, user }) => {
  const [loginToken] = useAtom(loginTokenAtom);
  // const [loginTokenCreated] = useAtom(loginTokenCreatedAtom);

  const loading = {
    box: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "100%",
      gap: 10,
    },
    progress: {
      width: 90,
      height: 90,
    },
  };
  return (
    <div
      style={{
        // display: "block",
        display: user ? "none" : loadingVisible ? "block" : "none",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2147483647,
        backgroundColor: theme === "dark" ? "#1f1f1f" : "#f4f5f7",
      }}
    >
      <Box sx={loading.box}>
        <CircularProgress style={loading.progress} />
        {loginToken || null}
      </Box>
    </div>
  );
};
