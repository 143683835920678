import { atom } from "jotai";

export const firebaseAtom = atom();
export const isDebouncingAtom = atom(false);
export const isDebouncingDelayAtom = atom(false);
export const eventDataModalTabValueAtom = atom(0);
export const currentStaffDataAtom = atom({ isUtility: true });
export const userThemeAtom = atom("automatic");
export const themeAtom = atom("dark");
export const userAtom = atom(null);
export const isAdminAtom = atom(false);
export const isSuperAdminAtom = atom(false);
export const isIdleAtom = atom(false);
export const isElectronAtom = atom(false);
export const listsAtom = atom([]);
export const stylingListsAtom = atom([]);
export const staffDataAtom = atom([]);
export const clientDataAtom = atom([]);
export const labelsAtom = atom([]);
export const labelColoursAtom = atom([]);
export const setSearchResultModalIDAtom = atom("");
export const setSearchResultModalKindAtom = atom("");
export const showingCalendarAtom = atom(true);
export const showEveryJobAtom = atom(true);
export const isMobileBrowserAtom = atom(false);
export const isMobileAtom = atom(false);
export const groupWhenFilteringAtom = atom(true);
export const filterWhenCompletingAtom = atom(true);
export const calendarEventsAtom = atom([]);
export const newJobObjectAtom = atom({
  allDay: false,
  assignedStaff: [],
  end: "",
  start: "",
  shootDate: "",
  bookedStart: "",
  bookedFinish: "",
  studio: "",
  confirmationStatus: "tbc",
  year: 0,
  title: "New Event",
  jobName: "",
  jobNumber: "",
  client: "",
  multipleDays: "",
  jobTypeFull: "",
  jobTypeShort: "",
  express: "",
  dueDate: null,
  expressDueDate: "",
  backgroundRolls: [],
  catering: [],
  creativeDayType: "",
  dayLength: "",
  dayType: "",
  finishTime: "",
  hmu: [],
  noRetouching: false,
  idList: "",
  idLabels: [],
  idLabelsPermanent: [],
  images: "",
  imagesProcessed: "",
  imagesProcessedNumbers: 0,
  imagesScheduledToBeShot: "",
  imagesSupplied: "",
  invoicingSpecialRequirements: "",
  lunch: "",
  models: [],
  operator: "",
  overtime: "",
  photographer: "",
  retoucher: "",
  retouchingSpecialRequirements: "",
  retouchingTime: "",
  shootSpecialRequirements: "",
  startTime: "",
  stylist: "",
  tracking: {
    created: {
      //   by: currentUser.id,
      //   at: new Date().toISOString(),
    },
    modified: [],
    trelloCardModified: [],
  },
  checklists: [
    // {
    //   name: "Colour Corrections",
    //   id: uuidv4(),
    //   checklistItems: [],
    // },
    // {
    //   name: "Colour Changes",
    //   id: uuidv4(),
    //   checklistItems: [],
    // },
    // {
    //   name: "Retouching Notes",
    //   id: uuidv4(),
    //   checklistItems: [],
    // },
  ],
  outsourcers: [],
  trelloCardID: "",
  operatorChecklist: {
    name: "Operator Checklist",
    id: "operator_checklist",
    checklistItems: [
      {
        checked: false,
        title:
          "Ensure the correct amount of images have been processed and recorded",
        id: "item 1",
      },
      {
        checked: false,
        title:
          "Check notes for any details about supplying to clients e.g Storyboards, Quickproofs etc...",
        id: "item 2",
      },
      {
        checked: false,
        title: "Ensure the Storyboard is complete",
        id: "item 3",
      },
      {
        checked: false,
        title: "Process Quickproofs if required",
        id: "item 4",
      },
      {
        checked: false,
        title: "Put JPEGS / TIFFS on working jobs",
        id: "item 5",
      },
      {
        checked: false,
        title: "Backup to client / photographer drive",
        id: "item 6",
      },
      {
        checked: false,
        title: "Backup to current archive (or client archive if they have one)",
        id: "item 7",
      },
      {
        checked: false,
        title: "Supply Storyboards",
        id: "item 8",
      },
      {
        checked: false,
        title: "Move card from Shooting to Prep to Send",
        id: "item 9",
      },
    ],
  },
  retoucherChecklist: {
    item1: false,
    item2: false,
    item3: false,
    item4: false,
    item5: false,
    item6: false,
    item7: false,
  },
});
export const calendarColoursAtom = atom({
  "Studio 1": {
    darkBackground: "hsla(345, 30%, 20%, 0.8)",
    darkText: "hsl(345, 70%, 60%)",
    background: "hsla(345, 70%, 90%, 0.8)",
    text: "hsl(345, 50%, 40%)",
  },
  "Studio 2A": {
    darkBackground: "hsla(30, 30%, 20%, 0.8)",
    darkText: "hsl(30, 70%, 60%)",
    background: "hsla(30, 70%, 90%, 0.8)",
    text: "hsl(30, 50%, 40%)",
  },
  "Studio 2B": {
    darkBackground: "hsla(205, 30%, 20%, 0.8)",
    darkText: "hsl(205, 70%, 60%)",
    background: "hsla(205, 70%, 90%, 0.8)",
    text: "hsl(205, 50%, 40%)",
  },
  "Studio 2C": {
    darkBackground: "hsla(170, 30%, 20%, 0.8)",
    darkText: "hsl(170, 70%, 60%)",
    background: "hsla(170, 70%, 90%, 0.8)",
    text: "hsl(170, 50%, 40%)",
  },
  "Studio 3": {
    darkBackground: "hsla(0, 0%, 40%, 0.8)",
    darkText: "hsl(0, 0%, 85%)",
    background: "hsla(0, 0%, 90%, 0.8)",
    text: "hsl(0, 00%, 45%)",
  },
  "Studio 4": {
    darkBackground: "hsla(0, 0%, 16%, 0.8)",
    darkText: "hsl(0, 0%, 70%)",
    background: "hsla(0, 0%, 75%, 0.8)",
    text: "hsl(0, 00%, 40%)",
  },
  Staff: {
    darkBackground: "hsla(46, 30%, 20%, 0.8)",
    darkText: "hsl(46, 70%, 60%)",
    background: "hsla(46, 70%, 90%, 0.8)",
    text: "hsl(46, 50%, 40%)",
  },
  "N/A": {
    darkBackground: "hsla(46, 30%, 20%, 0.8)",
    darkText: "hsl(46, 70%, 60%)",
    background: "hsla(46, 70%, 90%, 0.8)",
    text: "hsl(46, 50%, 40%)",

    // darkBackground: "hsla(355, 25%, 85%, 0.6)",
    // darkText: "hsl(355, 25%, 85%)",
    // background: "hsla(355, 45%, 25%, 0.6)",
    // text: "hsl(355, 45%, 25%)",
  },
  "Critical Events": {
    darkBackground: "hsla(285, 30%, 20%, 0.8)",
    darkText: "hsl(285, 70%, 60%)",
    background: "hsla(285, 70%, 90%, 0.8)",
    text: "hsl(285, 50%, 40%)",
  },
  "Post Production": {
    darkBackground: "hsla(230, 30%, 20%, 0.8)",
    darkText: "hsl(230, 70%, 60%)",
    background: "hsla(230, 70%, 90%, 0.8)",
    text: "hsl(230, 50%, 40%)",
  },
  Adam: {
    darkBackground: "hsla(110, 30%, 20%, 0.8)",
    darkText: "hsl(110, 70%, 60%)",
    background: "hsla(110, 70%, 90%, 0.8)",
    text: "hsl(110, 50%, 40%)",
  },
  Location: {
    darkBackground: "hsla(130, 30%, 15%, 0.8)",
    darkText: "hsl(130, 70%, 55%)",
    background: "hsla(130, 70%, 80%, 0.8)",
    text: "hsl(130, 50%, 40%)",
  },
});
export const jobTypesAtom = atom([]);
export const exportDatesModalOpenAtom = atom(false);
export const isDockHiddenAtom = atom(false);
export const isDevAtom = atom(false);
export const eventDataModalOpenAtom = atom(false);
export const isShootTabAtom = atom(false);
export const emailSelectorDialogOpenAtom = atom(false);
export const showWeekendsAtom = atom(false);
export const queryFilterAtom = atom(null);
export const loginTokenAtom = atom(null);
export const loginTokenCreatedAtom = atom(null);
