import React, { useState, useCallback, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { v4 as uuidv4 } from "uuid";
import BackgroundRollItem from "./BackgroundRollItem";
import debounce from "lodash.debounce";
import { useAtom } from "jotai";
import { isDebouncingAtom } from "../../../../-Atoms";

const BackgroundRolls = ({
  titleStyle,
  backgroundRolls,
  setBackgroundRolls,
  firebase,
  jobData,
}) => {
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [, setIsDebouncing] = useAtom(isDebouncingAtom);

  const debouncedUpdateFS = useCallback(
    debounce((updatedRolls) => {
      if (!jobData?.id) return;
      
      setUpdateInProgress(true);
      setIsDebouncing(true);
      
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update({ backgroundRolls: updatedRolls })
        .then(() => {
          setUpdateInProgress(false);
          setIsDebouncing(false);
        })
        .catch((e) => {
          console.log(e);
          setUpdateInProgress(false);
          setIsDebouncing(false);
        });
    }, 750),
    [firebase, jobData?.id, setIsDebouncing]
  );

  const addBackgroundRollItem = () => {
    const currentRolls = Array.isArray(backgroundRolls) ? backgroundRolls : [];
    const newitemObj = {
      id: uuidv4(),
      quantity: "",
      itemName: "",
    };
    const updatedRolls = [...currentRolls, newitemObj];
    setBackgroundRolls(updatedRolls);
    debouncedUpdateFS(updatedRolls);
  };

  const [showRemoveBackgroundRollItem, setShowRemoveBackgroundRollItem] = useState(false);
  
  const toggleShowRemoveBackgroundRollItem = () => {
    setShowRemoveBackgroundRollItem(true);
    setTimeout(() => {
      setShowRemoveBackgroundRollItem(false);
    }, 6000);
  };

  const handleUpdate = useCallback((updatedRolls) => {
    setBackgroundRolls(updatedRolls);
    debouncedUpdateFS(updatedRolls);
  }, [setBackgroundRolls, debouncedUpdateFS]);

  return (
    <>
      <div style={titleStyle}>
        <span>Background Rolls</span>
        <div>
          <IconButton onClick={toggleShowRemoveBackgroundRollItem}>
            <RemoveIcon />
          </IconButton>
          <IconButton onClick={addBackgroundRollItem}>
            <AddIcon />
          </IconButton>
        </div>
      </div>
      <div className="flex flex-col gap-4 pb-6 pt-2.5 px-8">
        {backgroundRolls?.map((bri, index) => (
          <BackgroundRollItem
            key={bri.id}
            item={bri}
            items={backgroundRolls}
            setItems={handleUpdate}
            index={index}
            showRemoveBackgroundRollItem={showRemoveBackgroundRollItem}
            updateInProgress={updateInProgress}
          />
        ))}
      </div>
    </>
  );
};

export default BackgroundRolls;