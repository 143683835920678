import React, { useState, useRef } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

const EmailField = ({ emails, setEmails, kind, visible, marginRight }) => {
  const [newEmail, setNewEmail] = useState("");
  const [newEmailVisible, setNewEmailVisible] = useState(false);
  const [newEmailError, setNewEmailError] = useState(false);
  const [newEmailButtonVisible, setNewEmailButtonVisible] = useState(true);

  const newEmailRef = useRef(newEmail);
  newEmailRef.current = newEmail;

  const handleDeleteEmailChip = (index) => {
    const tmpClients = [...emails];
    tmpClients.splice(index, 1);
    setEmails([...tmpClients]);
  };
  const handleNewEmailClick = () => {
    setNewEmailButtonVisible(false);
    setNewEmailVisible(true);
    setTimeout(() => {
      document.getElementById("newEmailField").focus();
    }, 100);
    setTimeout(() => {
      if (!newEmailRef.current) {
        setNewEmailButtonVisible(true);
        setNewEmailVisible(false);
        setNewEmailError(false);
      }
    }, 10000);
  };

  const confirmNewEmail = () => {
    if (!newEmailError) {
      setEmails([...emails, { email: newEmail }]);
      setNewEmailVisible(false);
      setNewEmailButtonVisible(true);
      setNewEmail("");
    }
  };

  const emailsContainerStyle = {
    minHeight: 10,
    display: visible ? "flex" : "none",
    marginBottom: 2,
    marginTop: 2,
    marginRight: marginRight,
    alignItems: "center",
  };
  return (
    <div style={emailsContainerStyle}>
      <p
        style={{
          marginTop: 8,
          marginBottom: 8,
          marginRight: 10,
          fontSize: "0.8em",
        }}
      >
        {kind}:
      </p>
      <div style={{ marginTop: 0 }}>
        <Stack direction="row" spacing={1}>
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            {emails &&
              emails.map((toEmail, i) => {
                if (toEmail) {
                  if (toEmail.email) {
                    return (
                      <Tooltip
                        key={`EmailChipToolTip-${i}`}
                        title={toEmail.email}
                      >
                        <Chip
                          style={{
                            margin: 2,
                            fontWeight: "bold",
                            fontSize: "0.7em",
                          }}
                          size="small"
                          key={`EmailChip-${i}`}
                          label={toEmail.name || toEmail.email}
                          onDelete={() => {
                            handleDeleteEmailChip(i);
                          }}
                        />
                      </Tooltip>
                    );
                  } else return null;
                } else return null;
              })}
            <TextField
              id="newEmailField"
              style={{
                marginLeft: 4,
                marginTop: 3,
                marginBottom: 2,
                display: newEmailVisible ? "block" : "none",
              }}
              size="small"
              InputProps={{
                endAdornment: (
                  <IconButton
                    size="small"
                    onClick={() => {
                      setNewEmailButtonVisible(true);
                      setNewEmailVisible(false);
                      setNewEmail("");
                      setNewEmailError(false);
                    }}
                  >
                    <CancelIcon fontSize="small" />
                  </IconButton>
                ),
              }}
              inputProps={{ sx: { height: 10, width: 190, fontSize: "11px" } }}
              error={newEmailError}
              value={newEmail || ""}
              onChange={(e) => {
                setNewEmail(e.target.value);
                // eslint-disable-next-line
                if (
                  e.target.value.match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  )
                ) {
                  setNewEmailError(false);
                } else setNewEmailError(true);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  confirmNewEmail();
                }
              }}
            />
            <IconButton
              sx={{ width: 26, height: 26 }}
              style={{ display: newEmailButtonVisible ? "flex" : "none" }}
              onClick={handleNewEmailClick}
            >
              <AddCircleIcon color="primary" sx={{ width: 20 }} />
            </IconButton>
          </div>
        </Stack>
      </div>
    </div>
  );
};

export default EmailField;
