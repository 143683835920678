import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { debounce } from "lodash";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import MDEditor from "@uiw/react-md-editor";

import IconButton from "@mui/material/IconButton";
import ImageIcon from "@mui/icons-material/Image";
import DownloadIcon from "@mui/icons-material/Download";

import { Switch } from "@mui/material";

import getJobName from "./GlobalFunctions/getJobName";

import { useAtom } from "jotai";
import { isIdleAtom, themeAtom } from "./-Atoms";
import { use100vh } from "react-div-100vh";

import PopupState, { bindHover, bindPopover } from "material-ui-popup-state";
import HoverPopover from "material-ui-popup-state/HoverPopover";

const OutsourcerJob = ({ firebase }) => {
  const [isIdle] = useAtom(isIdleAtom);
  const [theme] = useAtom(themeAtom);
  const { uid } = useParams();
  const height = use100vh();

  const [jobData, setJobData] = useState({});
  const [retouchingNotes, setRetouchingNotes] = useState({});
  const [showImages, setShowImages] = useState(true);

  useEffect(() => {
    if (uid) {
      let unsub = firebase
        .firestore()
        .collection("JobData")
        .doc(uid)
        .onSnapshot((doc) => {
          const data = doc.data();
          data.id = doc.id;
          let tmp;
          data.checklists.forEach((ch) => {
            if (ch.name === "Retouching Notes") tmp = ch;
          });
          setRetouchingNotes(tmp);
          setJobData(data);
        });
      return () => {
        unsub();
      };
    }
  }, [firebase, isIdle, uid]);

  return (
    <div
      style={{
        maxWidth: "100%",
        display: "flex",
        justifyContent: "center",
        padding: 10,
        height: height,
      }}
    >
      <Checklist
        firebase={firebase}
        jobData={jobData}
        retouchingNotes={retouchingNotes}
        isDarkMode={theme === "dark" ? true : false}
        showImages={showImages} // Pass showImages prop
        setShowImages={setShowImages} // Pass setShowImages prop
      />
    </div>
  );
};

export default OutsourcerJob;

const Checklist = ({
  firebase,
  jobData,
  retouchingNotes,
  isDarkMode,
  showImages,
  setShowImages,
}) => {
  return (
    <div
      style={{
        width: 650,
        padding: 10,
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        borderRadius: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              fontSize: "1.8rem",
              fontWeight: 800,
              color: isDarkMode ? "#c1c2c4" : "#1f1f1f",
              marginBottom: 10,
            }}
          >
            {getJobName(jobData)}
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: 10,
        }}
      >
        <span
          style={{
            marginRight: 10,
            color: isDarkMode ? "#c1c2c4" : "#1f1f1f",
            fontWeight: "bold",
          }}
        >
          Show Images
        </span>
        <Switch
          checked={showImages}
          onChange={(e) => setShowImages(e.target.checked)}
          inputProps={{ "aria-label": "Show Images" }}
          size="small"
        />
      </div>
      <div style={{ height: "calc(100% - 60px)", overflow: "auto" }}>
        <List sx={{ width: "100%", paddingTop: "3px", paddingBottom: "3px" }}>
          {retouchingNotes.checklistItems &&
            retouchingNotes.checklistItems.map((ci, index) => {
              if (!ci.excluded) {
                return (
                  <ChecklistItem
                    key={`${ci.title}_${index}_CheckListItem`}
                    firebase={firebase}
                    jobData={jobData}
                    checklist={retouchingNotes}
                    isDarkMode={isDarkMode}
                    index={index}
                    ci={ci}
                    showImages={showImages}
                  />
                );
              } else return null;
            })}
        </List>
      </div>
      <div style={{ marginTop: 40 }}> </div>
    </div>
  );
};

const ChecklistItem = ({
  isDarkMode,
  firebase,
  jobData,
  checklist,
  index,
  ci,
  showImages,
}) => {
  const updateOutsourcerJobChecklists = firebase
    .functions()
    .httpsCallable("updateOutsourcerJobChecklists");

  const updateChecklists = debounce(async (updatedJobData) => {
    try {
      await updateOutsourcerJobChecklists(updatedJobData);
    } catch (error) {
      console.error("Error updating checklists:", error);
    }
  }, 1000);

  const ListItemTextID = `${ci.title}_${index}_CheckListItem_Title`;
  const CheckboxID = `${ci.title}_${index}_CheckListItem_Checkbox`;

  const [title, setTitle] = useState(ci.title);
  const [checked, setChecked] = useState(ci.checked);

  const handleCheckboxToggle = async () => {
    const newChecked = !checked;
    setChecked(newChecked);
    ci.checked = newChecked;
    ci.outsourcer = newChecked;

    const updatedJobData = { ...jobData };
    updatedJobData.checklists = updatedJobData.checklists.map((ch) =>
      ch.name === "Retouching Notes" ? checklist : ch
    );

    updateChecklists(updatedJobData);
  };

  const handleDownloadImage = (e) => {
    e.stopPropagation();
    if (ci.image) {
      window.open(ci.image.replace("&dl=0", "&dl=1"), "_blank");
    }
  };

  const handleImageClick = (e) => {
    e.stopPropagation();
    if (ci.image) {
      window.open(ci.image, "_blank");
    }
  };

  useEffect(() => {
    setTitle(ci.title);
    setChecked(ci.checked);
  }, [ci]);

  return (
    <>
      <ListItem
        disablePadding
        className="checklistItem"
        style={{
          WebkitTouchCallout: "none",
          WebkitUserSelect: "none",
          khtmlUserSelect: "none",
          MozUserSelect: "none",
          msUserSelect: "none",
          userSelect: "none",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          borderRadius: 5,
          marginBottom: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginLeft: 20,
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checked}
                onClick={handleCheckboxToggle}
                disableRipple
                id={CheckboxID}
                size="small"
              />
            </ListItemIcon>
            <div
              id={ListItemTextID}
              style={{
                width: "90%",
                marginLeft: -25,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                cursor: "pointer",
              }}
              onClick={handleCheckboxToggle}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <MDEditor.Markdown
                  source={`${ci.checked ? "~~" : ""}${title}${
                    ci.checked ? "~~" : ""
                  }`}
                  style={{
                    background: "transparent",
                    width: "90%",
                    paddingTop: 5,
                    paddingBottom: 5,
                    color: ci.checked
                      ? "#808080"
                      : isDarkMode
                      ? "#ffffff"
                      : "#000000",
                    fontSize: "1rem",
                  }}
                />
              </div>
            </div>
          </div>
          {ci.image && (
            <>
              {/*  */}
              <PopupState variant="popover" popupId={`popover-${ci.id}`}>
                {(popupState) => (
                  <>
                    {showImages ? (
                      <img
                        src={ci.image.replace("&dl=0", "&raw=1")}
                        alt={ci.title}
                        style={{
                          maxHeight: 150,
                          maxWidth: 120,
                          marginRight: 2,
                          cursor: "pointer",
                        }}
                        {...bindHover(popupState)}
                      />
                    ) : (
                      <IconButton
                        {...bindHover(popupState)}
                        edge="end"
                        sx={{ marginRight: 1 }}
                        onClick={handleImageClick}
                      >
                        <ImageIcon fontSize="small" />
                      </IconButton>
                    )}
                    <HoverPopover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: "center",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "center",
                        horizontal: "right",
                      }}
                      slotProps={{
                        paper: {
                          style: {
                            padding: 10,
                            backgroundColor: isDarkMode ? "#2c2c2c" : "#ffffff",
                          },
                        },
                      }}
                    >
                      <img
                        src={ci.image.replace("&dl=0", "&raw=1")}
                        alt={ci.title}
                        style={{
                          width: "500px",
                          height: "500px",
                          objectFit: "contain",
                        }}
                      />
                    </HoverPopover>
                  </>
                )}
              </PopupState>
              {/* //  */}
              <IconButton
                edge="end"
                sx={{ marginRight: 2 }}
                onClick={handleDownloadImage}
              >
                <DownloadIcon fontSize="small" />
              </IconButton>
            </>
          )}
        </div>
      </ListItem>
    </>
  );
};
