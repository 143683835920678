import React, { useCallback } from "react";
import { Menu, MenuItem } from "@mui/material";
import { NestedMenuItem } from "./CustomGridCells";

const RightClickMenu = ({
  firebase,
  jobID,
  columnDefaults,
  setColumnDefaults,
  updateShotList,
  contextMenu,
  handleClose,
  isStaff,
  handleUnlockRow,
  handleLockRow,
  setSource,
}) => {
  const menuItemStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: 5,
    marginRight: 5,
  };

  const colourDotStyle = {
    display: "inline-block",
    width: 15,
    height: 15,
    borderRadius: "50%",
  };

  const setRowClass = useCallback(
    (rowId, newClass) => {
      setSource((prevSource) => {
        const updatedSource = prevSource.map((row) => {
          if (row.id === rowId) {
            return { ...row, myRowClass: newClass };
          }
          return row;
        });
        updateShotList(updatedSource);
        return updatedSource;
      });
    },
    [updateShotList, setSource]
  );

  const handleHideColumn = useCallback(async () => {
    handleClose();
    columnDefaults[contextMenu.model.prop].hidden = true;
    setColumnDefaults(columnDefaults);
    firebase.firestore().collection("JobData").doc(jobID).update({
      shotListColumns: columnDefaults,
    });
  }, [
    firebase,
    jobID,
    contextMenu,
    columnDefaults,
    handleClose,
    setColumnDefaults,
  ]);

  const handleClearColour = () => {
    if (contextMenu?.rowId) {
      setRowClass(contextMenu.rowId, "");
    }
    handleClose();
  };

  const handleSetColour = (colour) => {
    if (contextMenu?.rowId) {
      setRowClass(contextMenu.rowId, colour);
    }
    handleClose();
  };
  return (
    <Menu
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
      }
      disableAutoFocusItem
      PopoverClasses={{
        root: {
          disableEnforceFocus: true,
          disableAutoFocus: true,
        },
      }}
    >
      {contextMenu?.kind === "cell" && (
        <div>
          {isStaff &&
            (contextMenu?.isLocked ? (
              <MenuItem onClick={handleUnlockRow}>Unlock</MenuItem>
            ) : (
              <MenuItem onClick={handleLockRow}>Lock</MenuItem>
            ))}

          <NestedMenuItem
            label="Set Colour"
            parentMenuOpen={contextMenu !== null}
          >
            <MenuItem onClick={handleClearColour}>Clear Colour</MenuItem>
            <MenuItem
              onClick={() => {
                handleSetColour("red");
              }}
              style={menuItemStyle}
            >
              <span
                style={{
                  ...colourDotStyle,
                  backgroundColor: "rgba(255, 0, 0, 0.7)",
                }}
              ></span>
              Red
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleSetColour("green");
              }}
              style={menuItemStyle}
            >
              <span
                style={{
                  ...colourDotStyle,
                  backgroundColor: "rgba(0, 255, 0, 0.6)",
                }}
              ></span>
              Green
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleSetColour("teal");
              }}
              style={menuItemStyle}
            >
              <span
                style={{
                  ...colourDotStyle,
                  backgroundColor: "rgba(0, 255, 255, 0.5)",
                }}
              ></span>
              Teal
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleSetColour("blue");
              }}
              style={menuItemStyle}
            >
              <span
                style={{
                  ...colourDotStyle,
                  backgroundColor: "rgba(80, 80, 255, 0.5)",
                }}
              ></span>
              Blue
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleSetColour("purple");
              }}
              style={menuItemStyle}
            >
              <span
                style={{
                  ...colourDotStyle,
                  backgroundColor: "rgba(125, 65, 175, 0.5)",
                }}
              ></span>
              Purple
            </MenuItem>
          </NestedMenuItem>
        </div>
      )}

      {contextMenu?.kind === "column" && (
        <MenuItem onClick={handleHideColumn}>Hide Column</MenuItem>
      )}
    </Menu>
  );
};

export default RightClickMenu;
