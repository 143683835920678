import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import { useAtom } from "jotai";
import { isDebouncingAtom } from "../../../../-Atoms";

const BackgroundRollItem = ({
  item,
  items,
  setItems,
  index,
  showRemoveBackgroundRollItem,
  updateInProgress
}) => {
  const [localQuantity, setLocalQuantity] = useState(item.quantity || "");
  const [localItemName, setLocalItemName] = useState(item.itemName || "");
  const [isTyping, setIsTyping] = useState(false);
  const [isDebouncing] = useAtom(isDebouncingAtom);

  useEffect(() => {
    setLocalQuantity(item.quantity);
    setLocalItemName(item.itemName);
  }, [item.quantity, item.itemName]);

  const handleChange = (field, value) => {
    if (field === "quantity") {
      setLocalQuantity(value);
    } else {
      setLocalItemName(value);
    }
    setIsTyping(true);

    const updatedItems = items.map((i, idx) => 
      idx === index ? { ...i, [field]: value } : i
    );
    setItems(updatedItems);
  };

  const removeBackgroundRollItem = () => {
    const updatedItems = items.filter((_, idx) => idx !== index);
    setItems(updatedItems);
  };

  useEffect(() => {
    if (!updateInProgress) {
      setIsTyping(false);
    }
  }, [updateInProgress]);

  return (
    <div className="flex justify-between items-center gap-4 w-full">
      <TextField
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15 } }}
        label="Quantity"
        variant="filled"
        value={localQuantity}
        size="small"
        sx={{ width: "49%" }}
        InputProps={{
          disableUnderline: true,
          endAdornment: (isTyping || updateInProgress || isDebouncing) ? (
            <InputAdornment position="end">
              <CircularProgress size={20} />
            </InputAdornment>
          ) : null,
        }}
        onChange={(e) => {
          const numericValue = e.target.value.replace(/[^0-9]/g, "");
          handleChange("quantity", numericValue);
        }}
      />
      <TextField
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15 } }}
        label="Colour Name"
        variant="filled"
        value={localItemName}
        size="small"
        sx={{ width: "49%" }}
        InputProps={{
          disableUnderline: true,
          endAdornment: (isTyping || updateInProgress || isDebouncing) ? (
            <InputAdornment position="end">
              <CircularProgress size={20} />
            </InputAdornment>
          ) : null,
        }}
        onChange={(e) => handleChange("itemName", e.target.value)}
      />
      <IconButton
        sx={{ display: showRemoveBackgroundRollItem ? "block" : "none" }}
        onClick={removeBackgroundRollItem}
      >
        <RemoveIcon />
      </IconButton>
    </div>
  );
};

export default BackgroundRollItem;