import { commands } from "@uiw/react-md-editor";

const mdCommands = [
  commands.bold,
  commands.italic,
  commands.strikethrough,
  commands.group(
    [
      commands.title1,
      commands.title2,
      commands.title3,
      commands.title4,
      commands.title5,
      commands.title6,
    ],
    {
      name: "title",
      groupName: "title",
      buttonProps: { "aria-label": "Insert title" },
    }
  ),
  commands.group([], {
    name: "Colour",
    groupName: "Colour",
    icon: (
      <svg width="12px" height="12px" viewBox="0 0 16 16">
        <g id="surface1">
          <path
            fill="currentColor"
            style={{
              transform: "scale(1.4) translateX(-2px) translateY(-2px)",
            }}
            d="M 3.332031 12 L 12.667969 12 L 12.667969 14 L 3.332031 14 Z M 8.332031 2.667969 L 7.667969 2.667969 C 7.398438 2.667969 7.15625 2.828125 7.050781 3.074219 L 3.828125 10.667969 L 5.277344 10.667969 L 6.128906 8.667969 L 9.863281 8.667969 L 10.707031 10.667969 L 12.15625 10.667969 L 8.949219 3.074219 C 8.84375 2.828125 8.601562 2.667969 8.332031 2.667969 Z M 6.691406 7.332031 L 8 4.257812 L 9.300781 7.332031 Z M 6.691406 7.332031 "
          />
        </g>
      </svg>
    ),
    children: ({ close, execute, getState, textApi }) => {
      const setColour = (hex) => {
        const state = getState();
        const api = textApi;
        const startPos = state.selection.start;

        const text = state.text;
        const replacementText = `<colour ${hex}>${state.selectedText}</colour>`;

        const startSub = text.substring(0, startPos);

        api.replaceSelection(replacementText);
        api.setSelectionRange({
          start: startSub.length + replacementText.indexOf(state.selectedText),
          end:
            startSub.length +
            replacementText.indexOf(state.selectedText) +
            state.selectedText.length,
        });
        close();
      };

      const resetText = () => {
        const state = getState();
        const api = textApi;

        const replacementText = state.text.replace(
          /(<colou?r (#[A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})>)(.*?)(<\/colou?r>)/gm,
          state.selectedText
        );
        api.setSelectionRange({
          start: 0,
          end: textApi.textArea.value.length,
        });
        api.replaceSelection(replacementText);
        close();
      };

      const buttonStyle = {
        width: 16,
        height: 16,
        cursor: "pointer",
        margin: 0.5,
      };

      const greyArray = [
        "#FFFFFF",
        "#E8E8E8",
        "#D1D1D1",
        "#BABABA",
        "#A2A2A2",
        "#8B8B8B",
        "#747474",
        "#5D5D5D",
        "#454545",
        "#2F2F2F",
        "#171717",
        "#000000",
      ];

      const colourArrays = {
        redArray: [
          "#300402",
          "#600F07",
          "#911D0E",
          "#C12917",
          "#F23620",
          "#F14A3D",
          "#F4716A",
          "#F69E9C",
          "#FACECD",
        ],
        orangeArray: [
          "#311B05",
          "#63350E",
          "#935019",
          "#C46C25",
          "#F4852F",
          "#F79D49",
          "#F9B571",
          "#FACD9F",
          "#FDE5CF",
        ],
        yellowArray: [
          "#333209",
          "#67651B",
          "#9A972D",
          "#CDCA40",
          "#FFFC52",
          "#FFFD5F",
          "#FFFD7E",
          "#FFFDA4",
          "#FFFED2",
        ],
        limeArray: [
          "#1F3208",
          "#3D6319",
          "#5C952A",
          "#7AC63B",
          "#99F94C",
          "#ACFA5B",
          "#BFFA7B",
          "#D5FCA4",
          "#E9FDD0",
        ],
        greenArray: [
          "#0E3108",
          "#246319",
          "#3A9529",
          "#50C63A",
          "#65F84B",
          "#6FF85A",
          "#88F97A",
          "#ABFAA3",
          "#D4FCD1",
        ],
        forrestArray: [
          "#0D321C",
          "#236338",
          "#399554",
          "#4EC771",
          "#64F98C",
          "#6DF9A1",
          "#87FAB9",
          "#AAFBCF",
          "#D4FDE7",
        ],
        aquaArray: [
          "#0C3232",
          "#216465",
          "#369698",
          "#4AC9CB",
          "#5FFBFD",
          "#69FBFE",
          "#84FBFE",
          "#A9FCFE",
          "#D3FDFE",
        ],
        skyArray: [
          "#021931",
          "#073462",
          "#0D4E94",
          "#1468C6",
          "#1B83F7",
          "#409AF8",
          "#70B3F9",
          "#9ECBFB",
          "#CEE5FD",
        ],
        blueArray: [
          "#000230",
          "#000A61",
          "#001493",
          "#001FC3",
          "#002AF5",
          "#2342F6",
          "#606DF7",
          "#969CF9",
          "#CBCDFB",
        ],
        purpleArray: [
          "#170430",
          "#2E0E62",
          "#441993",
          "#5C25C4",
          "#7331F6",
          "#8E48F6",
          "#A871F6",
          "#C59FF9",
          "#E1CEFC",
        ],
        magentaArray: [
          "#2F0731",
          "#5F1663",
          "#902694",
          "#BF36C5",
          "#F045F7",
          "#EF55F7",
          "#F277F8",
          "#F5A2FA",
          "#F9CFFC",
        ],
        pinkArray: [
          "#300518",
          "#601132",
          "#911F4C",
          "#C12C65",
          "#F13A7F",
          "#F14D97",
          "#F373B0",
          "#F6A0CA",
          "#FACFE4",
        ],
      };

      const newShade = (hexColor, magnitude) => {
        hexColor = hexColor.replace(`#`, ``);
        if (hexColor.length === 6) {
          const decimalColor = parseInt(hexColor, 16);
          let r = (decimalColor >> 16) + magnitude;
          r > 255 && (r = 255);
          r < 0 && (r = 0);
          let g = (decimalColor & 0x0000ff) + magnitude;
          g > 255 && (g = 255);
          g < 0 && (g = 0);
          let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
          b > 255 && (b = 255);
          b < 0 && (b = 0);
          return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
        } else {
          return hexColor;
        }
      };

      function hexToRgb(hex) {
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
          return r + r + g + g + b + b;
        });

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
          ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
            }
          : null;
      }
      function luminance(r, g, b) {
        var a = [r, g, b].map(function (v) {
          v /= 255;
          return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
      }

      function calculateRatio(color1, color2) {
        const color1rgb = hexToRgb(color1);
        const color2rgb = hexToRgb(color2);

        const color1luminance = luminance(
          color1rgb.r,
          color1rgb.g,
          color1rgb.b
        );
        const color2luminance = luminance(
          color2rgb.r,
          color2rgb.g,
          color2rgb.b
        );

        const ratio =
          color1luminance > color2luminance
            ? (color2luminance + 0.05) / (color1luminance + 0.05)
            : (color1luminance + 0.05) / (color2luminance + 0.05);

        return ratio;
      }

      return (
        <div
          style={{
            padding: 10,
          }}
        >
          <div style={{ fontSize: 12, marginBottom: 10 }}>Text Colour</div>
          <div style={{ display: "flex", marginBottom: 8 }}>
            {greyArray &&
              greyArray.map((hex) => (
                <div
                  type="button"
                  style={{
                    ...buttonStyle,
                    backgroundColor: hex,
                    border:
                      calculateRatio(hex, "#ffffff") < 1 / 4.5
                        ? null
                        : `1px solid ${newShade(hex, -40)}`,
                  }}
                  onClick={() => setColour(hex)}
                ></div>
              ))}
          </div>

          <div style={{ display: "flex" }}>
            {colourArrays &&
              Object.keys(colourArrays).map((colour) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {colourArrays[colour].map((hex) => (
                    <div
                      type="button"
                      style={{
                        ...buttonStyle,
                        backgroundColor: hex,
                        border:
                          calculateRatio(hex, "#ffffff") < 1 / 4.5
                            ? null
                            : `1px solid ${newShade(hex, -40)}`,
                      }}
                      onClick={() => setColour(hex)}
                    ></div>
                  ))}
                </div>
              ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            <button onClick={resetText}>Reset to default</button>
          </div>
        </div>
      );
    },
    execute: (state, api) => {
      api.textArea.selectionStart = state.selection.start;
      api.textArea.selectionEnd = state.selection.end;
    },
    buttonProps: { "aria-label": "Insert Colour" },
  }),
  commands.link,
];

export default mdCommands;
