const fetch = require("isomorphic-fetch");
const Dropbox = require("dropbox").Dropbox;
const dbx = new Dropbox({
  fetch: fetch,
  accessToken:
    "b-TxMhg7wmcAAAAAABiOl_5JiyeefA0elglApZxmxg3Uc9n7uXMt65Um5ajpJjuC",
});

async function getDropboxFileRequest(path, title) {
  await dbx
    .filesCreateFolderV2({
      path: path,
      autorename: false,
    })
    .catch((error) => {
      if (error.error.error_summary.includes("path/conflict/folder")) {
        console.log(
          "technically im an error, but im an expected error so its all g bro"
        );
        console.log(
          "all im saying is, there was already a folder with that name, all good"
        );
      } else console.error(JSON.stringify(error));
    });
  let existingRequestURL;
  const existingRequests = await dbx.fileRequestsListV2().catch((error) => {
    console.log(error);
    return { error: true, errorText: "Could not connect to Dropbox..." };
  });
  existingRequests.result.file_requests.forEach((req) => {
    if (req.is_open) {
      if (req.title === title) {
        existingRequestURL = req.url;
      }
    }
  });
  if (existingRequestURL) return existingRequestURL;
  const res = await dbx
    .fileRequestsCreate({
      title: title,
      destination: path,
      open: true,
    })
    .catch(function (error) {
      console.error(JSON.stringify(error));
      return { error: true, errorText: error.error.error_summary };
    });
  console.log(res);
  return res.result.url;
}

async function getDropboxLink(path, setShowUploadScreen = () => {}) {
  const existingLinkRes = await dbx
    .sharingListSharedLinks({
      path: path,
      direct_only: true,
    })
    .catch((error) => {
      console.error(JSON.stringify(error));
      console.log(error);
      return {
        error: true,
        errorText: error.error.error_summary || error,
        result: { links: [] },
      };
    });
  if (!existingLinkRes) {
    return { error: true, errorText: "Could not connect to Dropbox..." };
  }

  if (existingLinkRes.error) {
    if (existingLinkRes.errorText.includes("path/not_found")) {
      console.log("path not found");

      const createFolderRes = await dbx
        .filesCreateFolderV2({
          path: path,
          autorename: false,
        })
        .catch((error) => {
          console.error(JSON.stringify(error));
          return { error: true, errorText: error.error.error_summary };
        });
      if (createFolderRes.error) {
        return createFolderRes;
      }
    } else return existingLinkRes;
  }

  if (existingLinkRes.result.links.length === 0) {
    const createLinkRes = await dbx
      .sharingCreateSharedLinkWithSettings({
        path: path,
        settings: {
          requested_visibility: "public",
          audience: "public",
          access: "viewer",
        },
      })
      .catch((error) => {
        console.error(JSON.stringify(error));
        return { error: true, errorText: error.error.error_summary };
      });
    return createLinkRes.result.url;
  } else {
    return existingLinkRes.result.links[0].url;
  }
}

export { getDropboxFileRequest, getDropboxLink };
